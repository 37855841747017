function importAll(r) {
  return r.keys().map(r)
}

export const Lacosta = {
  smallRoom: importAll(
    require.context("./images/Lacosta/Small/", false, /\.(png|jpe?g|svg)$/)
  ),
  middleRoom: importAll(
    require.context("./images/Lacosta/Middle/", false, /\.(png|jpe?g|svg)$/)
  ),
  masterRoom: importAll(
    require.context("./images/Lacosta/Master/", false, /\.(png|jpe?g|svg)$/)
  ),
  r2b2: importAll(
    require.context("./images/Lacosta/2b2r/", false, /\.(png|jpe?g|svg)$/)
  ),
  r3b2: importAll(
    require.context("./images/Lacosta/3b2r/", false, /\.(png|jpe?g|svg)$/)
  ),
  r3b3: importAll(
    require.context("./images/Lacosta/3b4r/", false, /\.(png|jpe?g|svg)$/)
  ),
}

export const GeoR = {
  middleRoom: importAll(
    require.context("./images/GeoR/Middle/", false, /\.(png|jpe?g|svg)$/)
  ),
  masterRoom: importAll(
    require.context("./images/GeoR/Master/", false, /\.(png|jpe?g|svg)$/)
  ),
  r2b2: importAll(
    require.context("./images/GeoR/2b2r/", false, /\.(png|jpe?g|svg)$/)
  ),
  r3b2: importAll(
    require.context("./images/GeoR/3b2r/", false, /\.(png|jpe?g|svg)$/)
  ),
  r3b3: importAll(
    require.context("./images/GeoR/4b3r/", false, /\.(png|jpe?g|svg)$/)
  ),
}

export const GeoSense = {
  smallRoom: importAll(
    require.context("./images/GeoSense/Small/", false, /\.(png|jpe?g|svg)$/)
  ),
  middleRoom: importAll(
    require.context("./images/GeoSense/Middle/", false, /\.(png|jpe?g|svg)$/)
  ),
  masterRoom: importAll(
    require.context("./images/GeoSense/Master/", false, /\.(png|jpe?g|svg)$/)
  ),
  r2b2: importAll(
    require.context("./images/GeoSense/2b2r/", false, /\.(png|jpe?g|svg)$/)
  ),
  r3b2: importAll(
    require.context("./images/GeoSense/3b2r/", false, /\.(png|jpe?g|svg)$/)
  ),
  r3b3: importAll(
    require.context("./images/GeoSense/2b1r/", false, /\.(png|jpe?g|svg)$/)
  ),
}
