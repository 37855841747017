import { GeoR, GeoSense, Lacosta } from "./propertyImage"

const properties = {
  lacosta: {
    id: 1,
    img:
      "https://www.sunwayproperty.com/images/default-source/malaysia-properties/v1011_facade2_01-merge2_1280x628.tmb-1280x628.jpg",
    short_name: "LaCosta",
    name: "LaCosta, Sunway South Quay",
    address: "Sunway South Quay, Bandar Sunway, 47500 Subang Jaya, Selangor",
    details:
      "At LaCosta, a pristine 28-acre lake surrounded by natural greens forms a tranquil backdrop to an inspiring lakeside lifestyle. Lush sky gardens and landscape podiums blend effortlessly with contemporary architecture. Relax on the sands of your own private beach pool in the city, or change pace and head for the award-winning entertainment, leisure, and hospitality attractions that Sunway Resort City offers. All right at your door step, as are leading educational and medical facilities.",
    facilities: [
      "Barbeque Area",
      "Tennis Court",
      "24 hours CCTV surveillance",
      "Wading Pool",
      "Children playground",
      "Sandy beach pool",
      "Outdoor lounge and BBQ deck",
      "Multipurpose hall",
      "Jacuzzi",
      "Gated and guarded",
    ],
    units: [
      {
        type: "Whole Units",
        name: "2 Rooms 2 Bathrooms",
        price: "RM3300",
        img: Lacosta.r2b2,
      },
      {
        type: "Whole Units",
        name: "3 Rooms 2 Bathrooms",
        price: "RM3500",
        img: Lacosta.r3b2,
      },
      {
        type: "Whole Units",
        name: "3 Rooms 3 Bathrooms",
        price: "RM3700",
        img: Lacosta.r3b3,
      },
    ],
    room_only: [
      {
        type: "Room Only",
        name: "Master Room",
        price: "RM1600",
        img: Lacosta.masterRoom,
      },
      {
        type: "Room Only",
        name: "Middle Room",
        price: "RM1100",
        img: Lacosta.middleRoom,
      },
      {
        type: "Room Only",
        name: "Small Room",
        price: "RM950",
        img: Lacosta.smallRoom,
      },
    ],
  },
  geor: {
    id: 2,
    img:
      "https://dbv47yu57n5vf.cloudfront.net/s3fs-public/31684_1574678271_5EdgPro5_sunway_geosense_sunway_south_quay_bandar_sunway_1900080552400756705_0.jpg",
    short_name: "Geo Residence",
    name: "Sunway Geo Residence",
    address:
      "Persiaran Tasik Timur, Sunway South Quay, Bandar Sunway, 47500 Subang Jaya, Selangor",
    details:
      "At Sunway Geo Residences, all your world-class conveniences are a stone’s throw away from home. Where everything you need is just a walk away. Work, fun, entertainment. Variety. Its all here.",
    facilities: [
      "Barbeque Area",
      "Tennis Court",
      "24 hours CCTV surveillance",
      "Wading Pool",
      "Children playground",
      "Swimming Pool",
    ],
    units: [
      {
        type: "Whole Units",
        name: "Studio",
        price: "RM2500",
        img: GeoR.r3b2,
      },
      {
        type: "Whole Units",
        name: "2 Rooms 2 Bathrooms",
        price: "RM3000",
        img: GeoR.r2b2,
      },
      {
        type: "Whole Units",
        name: "3 Rooms 2 Bathrooms",
        price: "RM3900",
        img: GeoR.r3b3,
      },
    ],
    room_only: [
      {
        type: "Room Only",
        name: "Master Room",
        price: "RM1600",
        img: GeoR.masterRoom,
      },
      {
        type: "Room Only",
        name: "Middle Room",
        price: "RM1200",
        img: GeoR.middleRoom,
      },
    ],
  },
  geo_sense: {
    id: 2,
    img:
      "https://www.sunwayconstruction.com.my/wp-content/uploads/2019/09/GeoResi.jpg",
    short_name: "Geo Residence",
    name: "Sunway GeoSense",
    address:
      "Persiaran Tasik Timur, Sunway South Quay, Bandar Sunway, 47500 Subang Jaya, Selangor",
    details:
      "At Sunway Geo Residences, all your world-class conveniences are a stone’s throw away from home. Where everything you need is just a walk away. Work, fun, entertainment. Variety. Its all here.",
    facilities: [
      "Barbeque Area",
      "Tennis Court",
      "24 hours CCTV surveillance",
      "Wading Pool",
      "Children playground",
      "Swimming Pool",
    ],
    units: [
      {
        type: "Whole Units",
        name: "Studio",
        price: "RM2500",
        img: GeoSense.r3b3,
      },
      {
        type: "Whole Units",
        name: "2 Rooms 2 Bathrooms",
        price: "RM3000",
        img: GeoSense.r2b2,
      },
      {
        type: "Whole Units",
        name: "3 Rooms 2 Bathrooms",
        price: "RM3900",
        img: GeoSense.r3b2,
      },
    ],
    room_only: [
      {
        type: "Room Only",
        name: "Master Room",
        price: "RM1600",
        img: GeoSense.masterRoom,
      },
      {
        type: "Room Only",
        name: "Middle Room",
        price: "RM1200",
        img: GeoSense.middleRoom,
      },
      {
        type: "Room Only",
        name: "Small Room",
        price: "RM1000",
        img: GeoSense.smallRoom,
      },
    ],
  },
}

export default properties
